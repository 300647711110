import { useQuery } from "@apollo/client";
import CalendarLink from "components/Calendar/CalendarLink";
import moment from "moment";
import { useMemo } from "react";
import { CSVLink } from "react-csv";
import {
  Avatar,
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Table,
  Text,
} from "tabler-react";
import useReactRouter from "use-react-router";
import { GET_CAMP_BY_ID } from "../../../graphql/GET_CAMP_BY_ID";
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from "../../../graphql/types/GET_CAMP_BY_ID";
import useGetRegisteredStudents from "../../user/registrations/hooks/useGetRegisteredStudents";

const EventController = ({ eventId }) => {
  const { history } = useReactRouter();
  const { loading, data } = useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
    variables: {
      campId: Number(eventId),
    },
  });

  const { data: registrationData, loading: registrationLoading } =
    useGetRegisteredStudents({ campId: eventId });

  const participants = useMemo(() => {
    if (!registrationData?.getRegisteredStudents) return [];

    return registrationData?.getRegisteredStudents.filter(
      (student) => student.status === "Confirmed",
    );
  }, [registrationData]);

  const participantsCSV = useMemo(() => {
    if (!registrationData?.getRegisteredStudents) return [];

    return registrationData?.getRegisteredStudents
      .filter((student) => student.status === "Confirmed")
      .map((student) => {
        let studentOrg = null;
        let studentLicense = null;
        if (student.orgCredentials.length > 0) {
          studentOrg = student.orgCredentials[0];
          studentLicense = student.orgCredentials.find(
            (org) =>
              org.org_id === studentOrg.org_id && (org.license || org.nac_slug),
          );
        }
        return {
          id: student.id,
          name: student.first_name + " " + student.last_name,
          registrationId: student.user_registrations_id,
          address: `${student?.city ?? ""} ${student?.province ?? ""}`,
          orgSlug: studentOrg?.org_slug?.toUpperCase(),
          membership: studentOrg?.membership,
          license: studentLicense?.nac_slug
            ? `${
                (studentLicense?.nac_slug?.toUpperCase() ?? "") +
                " " +
                (studentLicense?.license_number ?? "")
              }`
            : `${
                (studentLicense?.license ?? "") +
                " " +
                (studentLicense?.license_number ?? "")
              }`,
          expires: studentOrg?.expires,
          dob: student.dob,
          gender: student.gender,
          country: studentLicense?.nac_country,
        };
      });
  }, [registrationData]);

  return (
    <Dimmer
      loader={loading || registrationLoading}
      active={loading || registrationLoading}
    >
      <Card>
        <Card.Header>
          <Card.Title>
            <strong
              className={"cursor-pointer"}
              onClick={() =>
                history.push(`/event/registration-list/${eventId}`)
              }
            >
              <CalendarLink
                eventName={data?.getCampById?.camp_name}
                eventId={Number(eventId)}
              />
            </strong>
            {} // Controller Report
          </Card.Title>
          <Card.Options>
            <Button outline color="secondary">
              <CSVLink
                data={participantsCSV}
                filename="event_participants_control.csv"
                className="ml-0"
              >
                <Icon name="download" className="mr-1" />
                CSV
              </CSVLink>
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader>Name</Table.ColHeader>
                    <Table.ColHeader>Membership</Table.ColHeader>
                    <Table.ColHeader>License</Table.ColHeader>
                    <Table.ColHeader>Expires</Table.ColHeader>
                    <Table.ColHeader>DOB (Age)</Table.ColHeader>
                    <Table.ColHeader>Gender</Table.ColHeader>
                    <Table.ColHeader>Country</Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {participants.map((student) => {
                    let studentOrg = null;
                    let studentLicense = null;
                    if (student.orgCredentials.length > 0) {
                      studentOrg = student.orgCredentials[0];
                      studentLicense = student.orgCredentials.find(
                        (org) =>
                          org.org_id === studentOrg.org_id &&
                          (org.license || org.nac_slug),
                      );
                    }
                    let campGroupName = "";
                    if (student.campGroups && student.campGroups.length > 0)
                      campGroupName = student.campGroups[0].name;

                    let statusIcon = "bg-danger";

                    if (
                      moment(student.end).diff(studentOrg?.expires, "days") > 0
                    ) {
                      statusIcon = "bg-warning";
                    }

                    if (
                      moment(student.end).diff(studentOrg?.expires, "days") < 0
                    ) {
                      statusIcon = "bg-success";
                    }

                    if (moment().diff(studentOrg?.expires, "days") > 0) {
                      statusIcon = "bg-danger";
                    }

                    return (
                      <Table.Row key={student.id}>
                        <Table.Col>
                          <Avatar
                            size="md"
                            className="cursor-pointer"
                            status={student.status_color ?? "danger"}
                            imageURL={
                              student?.profile_avatar
                                ? `/api/s3/uploads/${student.profile_avatar}`
                                : "//www.gravatar.com/avatar?d=mp"
                            }
                            onClick={() =>
                              history.push(
                                `/events/registrations/${student.user_registrations_id}/${student.id}`,
                              )
                            }
                          />
                        </Table.Col>
                        <Table.Col>
                          <strong>
                            <Text
                              className="cursor-pointer d-block"
                              onClick={() =>
                                history.push(
                                  `/events/registrations/${student.user_registrations_id}/${student.id}`,
                                )
                              }
                            >
                              {student.first_name + " " + student.last_name}
                            </Text>
                            <Text.Small className="d-block text-muted">
                              {student.city ?? ""}
                              {student.province && ", " + student.province}
                            </Text.Small>
                          </strong>
                        </Table.Col>
                        <Table.Col>
                          {(studentOrg?.org_slug.toUpperCase() ?? "") +
                            " " +
                            (studentOrg?.membership ?? "")}
                        </Table.Col>
                        <Table.Col>
                          {studentLicense?.nac_slug
                            ? `${
                                (studentLicense?.nac_slug?.toUpperCase() ??
                                  "") +
                                " " +
                                (studentLicense?.license_number ?? "")
                              }`
                            : `${
                                (studentLicense?.license ?? "") +
                                " " +
                                (studentLicense?.license_number ?? "")
                              }`}
                        </Table.Col>
                        <Table.Col>
                          {studentOrg?.expires && (
                            <span className={`status-icon ${statusIcon}`} />
                          )}
                          {studentOrg?.expires}
                        </Table.Col>
                        <Table.Col>
                          {moment(student.dob).format("YYYY-MM-DD") + "()"}
                        </Table.Col>
                        <Table.Col>{student.gender}</Table.Col>
                        <Table.Col>{studentLicense?.nac_country}</Table.Col>
                        <Table.Col>
                          <Icon name="more-vertical" />
                        </Table.Col>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </Dimmer>
  );
};

export default EventController;
